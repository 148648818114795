@import '../../theme/variables';
@import '../../theme/core';

.hero {
    background-image: url('../../assets//img/bg-results/bg-semm.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 300px;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;

    .content-hero {
        display: flex;
        align-content: center;
        align-items: center;
        width: 50%;
        .title {
            color: $white;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
        }
    }
}

.covid-page{
    .sub-title-covid{
        margin-top: 40px;
        color: $primaryColor;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
    }

    .title-covid{
        margin-top: 10px;
        color: $primaryColor;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
    }

    .label-covid{
        margin-bottom: 30px;
        color: $primaryColor;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
    }

    .header-form {
        width: 50%;
    } 

    .content-form {
        width: 40%;
    }

    .test-result {
        .content-infection-risk {
            height: auto;
            border: solid 1px #e6ebf1;
            background-color: #ffffff;
            padding-top: 40px;
            padding-bottom: 40px;
            padding-left: 60px;
            padding-right: 60px;


            .sub-title-covid{
                color: $primaryColor;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
            }

            .sub-title-risk{
                color: #43484d;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
            }


            .result-box-not-risk {
                margin-top: 20px;
                width: 120px;
                height: 60px;
                border-radius: 4px;
                background-color:#e5fefc;
            }

            .result-box-low {
                margin-top: 20px;
                width: 120px;
                height: 60px;
                border-radius: 4px;
                background-color:#e8fdff;
            }

            .result-box-medium {
                margin-top: 20px;
                width: 120px;
                height: 60px;
                border-radius: 4px;
                background-color: #fff7e7;
            }

            .result-box-hight {
                margin-top: 20px;
                width: 120px;
                height: 60px;
                border-radius: 4px;
                background-color: #ffe7ea;;
            }

            .title{
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                padding: 13px 14px;

                &.low {
                    color: #00c5dc;
                }
                &.medium {
                    color: #ffaa00;
                }
                &.hight {
                    color: #fb314a;
                }
                &.not-risk  {
                    color: #2ec4b6;
                }
            }
            
        }
    }

    .flex-cw-0.6 {
        flex: 0.6 0 0;
    }
    
    .flex-cw-4 {
        flex: 4 0 0;
    }
// New design Input Tel
.telInput-base {
    margin-top: 25px;
    margin-bottom: 8px;
    margin-left: 8px;
    margin-right: 8px;
    width: calc((100% / 3) - 18px);
    font: inherit;
  }
  
  .telInput-base-before {
    content: 'Telefono movil*';
    font-size: 12px;
    position: absolute;
    top: -18px;
  }
  
  .telInput-flag {
    height: 56px;
    cursor: pointer !important;
  }
  
  .telInput-selectFlag {
    width: 56px;
    height: 54px;
    padding: 18.5px 14px;
    border: none;
    background: $white !important;
  }
  
  .teInput-formControl {
    color: $grayFont;
    font-size: 16px !important;
    padding-left: 62px !important;
    height: 56px !important;
    border-radius: 4px !important;
    box-shadow: 0 1px 2px #fff inset !important;
    &:focus {
      outline: -webkit-focus-ring-color auto 0 !important;
    }
  }
  
  .telInput {
    @extend .telInput-base;
  
    &::before {
      @extend .telInput-base-before;
      color: $lightGrayText;
    }
  
    .flag-dropdown {
      @extend .telInput-flag;
      .selected-flag {
        @extend .telInput-selectFlag;
        border-right: 1px $lightGray solid;
        &:focus {
          border: 2px $lightGray solid;
        }
      }
    }
    
    .form-control {
      @extend .teInput-formControl;
      border-color: $lightGray !important;
    }
  
    &:focus {
      border: 2px $lightGray solid;
      outline: -webkit-focus-ring-color auto 0 !important;
    }
  }
  
  .telInputError {
    @extend .telInput-base;
  
    &::before {
      @extend .telInput-base-before;
      color: $redError;
    }
  
    .flag-dropdown {
      @extend .telInput-flag;
      .selected-flag {
        @extend .telInput-selectFlag;
        border-right: 1px $redError solid;
        &:focus {
          border: 2px $redError solid;
        }
      }
    }
    
    .form-control {
      @extend .teInput-formControl;
      border-color: $redError !important;
    }
  
    &:focus {
      border: 2px $redError solid;
      outline: -webkit-focus-ring-color auto 0 !important;
    }
  }
  
  
  // styles input tel for contact section
  
  
  .telInputNoLabel {
  
    @extend .telInput-base;
  
    .flag-dropdown {
      @extend .telInput-flag;
      .selected-flag {
        @extend .telInput-selectFlag;
        border-right: 1px $lightGray solid;
        &:focus {
          border: 2px $lightGray solid;
        }
      }
    }
    
    .form-control {
      @extend .teInput-formControl;
      border-color: $lightGray !important;
    }
  
    &:focus {
      border: 2px $lightGray solid;
      outline: -webkit-focus-ring-color auto 0 !important;
    }
  }
  
  .telInputNoLabelError {
    @extend .telInput-base;
  
    .flag-dropdown {
      @extend .telInput-flag;
      .selected-flag {
        @extend .telInput-selectFlag;
        border-right: 1px $redError solid;
        &:focus {
          border: 2px $redError solid;
        }
      }
    }
    
    .form-control {
      @extend .teInput-formControl;
      border-color: $redError !important;
    }
  
    &:focus {
      border: 2px $redError solid;
      outline: -webkit-focus-ring-color auto 0 !important;
    }
  }
  
  // 
  
  // New design Input Tel

    @media #{$lt-sm} {
        .header-covid {
            align-items: flex-start !important;
            width: 100% !important; 
            .sub-title-covid, .title-covid {
                text-align: left !important;
                
            }
        }

        .content-form { 
            width: 100% !important; 
        }
        .container {
          width: 90%;
        }

        .test-result {
          .content-infection-risk {
            padding-left: 30px;
            padding-right: 30px;
              .flex {
                  flex-direction: column;
              }
              .result-box-not-risk, 
              .result-box-low, 
              .result-box-medium,
              .result-box-hight {
                  margin: 20px 0;
              }
          }
        }
        .telInput {
          width: calc(100%  - 18px) !important;
        }
            
    }

    @media #{$lt-md} {
        .result-box-not-risk, 
        .result-box-low,
        .result-box-medium,
        .result-box-hight {
            margin-top: 50px !important;
        }
        .telInput {
          width: calc(100%  - 18px) !important;
        }
    }

    @media #{$lt-lg} {
        .header-covid {
            width: 80%;
        }
        .content-form { 
            width: 80%;
        }
    }

    @media #{$lt-xl}{
        .result-box-not-risk, 
        .result-box-low,
        .result-box-medium,
        .result-box-hight {
            margin-top: 35px !important;
        }
    }
}

