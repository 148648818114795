@import './_variables.scss';

* {
    font-family: $fontFamily !important;      
}
  
html, body {
    padding: 0;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}


/* FLEX-BOX */
.flex {
    display: flex;
}
  
.jc-c {
    justify-content: center !important;
}

.jc-b {
    justify-content: space-between;
}

.jc-e {
    justify-content: space-evenly;
}

.jc-a {
    justify-content: space-around;
}

.flex-row {
    flex-direction: row !important;
}

.flex-col {
    flex-direction: column;
}

.align-ic {
    align-items: center !important;
}

.align-ib {
    align-items: baseline;
}

.align-ie {
    align-items: flex-end;
}

.align-sb {
    align-self: baseline;
}

.align-sc {
    align-self: center;
}

.align-se {
    align-self: flex-end;
}

.flex-center {
    @extend .flex;
    @extend .align-ic;
}

.flex-full {
    @extend .flex;
    @extend .align-ic;
    @extend .jc-c;
}

.fje {
    @extend .flex;
    justify-content: flex-end;
}

.wrap {
    flex-wrap: wrap;
}
  
/* FLEX-BOX */

.container {
    margin: 0 auto;
    width: 80%;
}

/* Font */

.font-60 {
    font-size: 60px;
}

.font-55 {
    font-size: 55px;
}

.font-50 {
    font-size: 50px;
}

.font-40 {
    font-size: 40px;
}

.font-30 {
    font-size: 30px;
}

.font-25 {
    font-size: 25px;
}

.font-24 {
    font-size: 24px;
}

.font-20 {
font-size: 20px;
}

.font-18 {
    font-size: 18px !important;
}

.font-16 {
    font-size: 16px;
}

.font-14 {
    font-size: 14px;
}

.font-12 {
    font-size: 12px;
}

.font-10 {
    font-size: 10px;
}

.font-4 {
    font-size: 4em;
}

.font-2 {
    font-size: 2em;
}

.font-3 {
    font-size: 3em;
}

.font-09 {
    font-size: 0.9em;
}

.font-normal-all{
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    font-weight: normal;
}
.font-weight-b{
    font-weight: bold !important;
}

.x-small {
    font-size: 0.75rem !important;
}

.sm-small {
    font-size: 0.875rem;
}

.strong {
    font-weight: 600;
}

.strong-light {
    font-weight: bolder;
}

.normal {
    font-weight: normal;
}

.light {
    font-weight: 300;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-justify {
    text-align: justify;
}

.text-shadow {
    text-shadow: 0 1px 3px rgba(0,0,0,.3);
}

.underline {
    text-decoration: underline !important;
}

.text-line {
    text-decoration: line-through;
}
/* Font */

/* Margin */
.m-b1 {
    margin-bottom: -1px;
}

.m-b0 {
    margin-bottom: 0;
}

.m-b5 {
    margin-bottom: 5px;
}

.m-a {
    margin: auto;
}

.m-b10 {
    margin-bottom: 10px;
}

.m-b14 {
    margin-bottom: 14px;
}

.m-b16 {
    margin-bottom: 16px;
}

.m-b20 {
margin-bottom: 20px !important;
}

.m-b30 {
    margin-bottom: 30px;
}

.m-b40 {
    margin-bottom: 40px !important;
}

.m-b60 {
    margin-bottom: 60px;
}

.m-t8p {
    margin-top: 8%;
}

.m-t0 {
    margin-top: 0;
}

.m-t14 {
    margin-top: 14px;
}

.m-t15 {
    margin-top: 15px;
}

.m-t16 {
    margin-top: 16px;
}

.m-t20 {
    margin-top: 20px !important;
}

.m-t25 {
    margin-top: 25px !important;
}

.m-t5 {
    margin-top: 5px;
}

.m-t10 {
    margin-top: 10px;
}

.m-ti10 {
    margin-top: 10px !important;
}

.m-t30 {
    margin-top: 30px;
}

.m-t40 {
    margin-top: 40px !important;
}

.m-t60 {
    margin-top: 60px;
}

.m-t64 {
    margin-top: 64px;
}

.m-t80 {
    margin-top: 80px;
}

.m-s10 {
    margin: 0 10px;
}

.m-r60 {
    margin-right: 60px;
}

.m-r40 {
    margin-right: 40px;
}

.m-r20 {
    margin-right: 20px;
}

.m-r15 {
    margin-right: 15px !important;
}

.m-r5 {
    margin-right: 5px !important;
}

.m-r2 {
    margin-right: 2px !important;
}

.m-l5 {
    margin-left: 5px !important;
}

.m-l8 {
    margin-left: 8px !important;
}

.m-l10 {
    margin-left: 10px;
}

.m-l20 {
    margin-left: 20px;
}

.m-r5 {
    margin-right: 5px;
}

.m-r10 {
    margin-right: 10px;
}

.nm {
    margin: 0;
}
/* Margin */

/* PADDING */
.p-v48 {
    padding: 48px 0;
}
/* PADDING */

/* HEIGHT */
.h-80vh {
  height: 80vh;
}
/* HEIGHT */
/* LOGO */
.logo {
    width: auto;
    height: 60px;
}
/* LOGO */

/*BG-COLOR */
.bg-color-white {
    background-color: #ffffff;
}
/*BG-COLOR */

/*COLOR */

.color-black {
    color: $black !important;
}

.color-gray {
    color: $grayFont !important;
}

.default {
    div { color: #b0b2b4 !important; }
}

li {
    list-style-type: none;
    &:focus {
        outline: none !important;
    }
}

/* AvenirNext-regular - latin */
@font-face {
    font-family: 'AvenirNext';
    font-style: normal;
    font-weight: 400;
    src: url('../assets/fonts/AvenirNext/AvenirNextLTPro-Regular.eot'); /* IE9 Compat Modes */
    src: local('AvenirNext Regular'), local('AvenirNext-Regular'),
         url('../assets/fonts/AvenirNext/AvenirNextLTPro-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../assets/fonts/AvenirNext/AvenirNextLTPro-Regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../assets/fonts/AvenirNext/AvenirNextLTPro-Regular.woff') format('woff'), /* Modern Browsers */
         url('../assets/fonts/AvenirNext/AvenirNextLTPro-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../assets/fonts/AvenirNext/AvenirNextLTPro-Regular.svg#AvenirNext') format('svg'); /* Legacy iOS */
}
  
/* AvenirNext-600 - latin */
@font-face {
    font-family: 'AvenirNext';
    font-style: normal;
    font-weight: 600;
    src: url('../assets/fonts/AvenirNext/AvenirNextLTPro-Bold.eot'); /* IE9 Compat Modes */
    src: local('AvenirNext SemiBold'), local('AvenirNext-SemiBold'),
            url('../assets/fonts/AvenirNext/AvenirNextLTPro-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('../assets/fonts/AvenirNext/AvenirNextLTPro-Bold.woff2') format('woff2'), /* Super Modern Browsers */
            url('../assets/fonts/AvenirNext/AvenirNextLTPro-Bold.woff') format('woff'), /* Modern Browsers */
            url('../assets/fonts/AvenirNext/AvenirNextLTPro-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
            url('../assets/fonts/AvenirNext/AvenirNextLTPro-Bold.svg#AvenirNext') format('svg'); /* Legacy iOS */
}