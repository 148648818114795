// FontFamily asistensi
$fontFamily: 'AvenirNext', 'Open Sans', sans-serif;

// Primary Color
$primaryColor: #403B84;
$primaryShadow: 0 10px 16px 0 rgba(251, 49, 74, 0.16);

// Seccondary LightBlue Color
$secondaryColor: #200844;
$secondaryButton: linear-gradient(90deg, rgba(33,170,183,1) 0%, rgba(13,150,163,1) 100%);
$secondaryShadow: 0 10px 16px 0 rgba(32, 8, 68, 0.16);

// DarkGray text
$darkGray: #3c3c3c;
$grayFont: #43484d;
$lightGrayText: #9aabb5;
$lightGray: #e6ebf1;

$redError: #e32726;
$greenSuccess: #58D68D;
$greenButton: linear-gradient(90deg, rgba(68,214,75,1) 0%, rgba(58,179,64,1) 100%);

// LightGray text
$lightGray: #999;
$backGray: #eaeaea;

// LightGray background
$lightPrimaryColor: #ffe7ea;


$grayLine: #ccc;

$white: #fff;
$black: #000;


/* Media queries */
$xs-max: 599px; // xs max-width
$sm-max: 959px; // sm max-width
$md-max: 1279px; // md max-width
$lg-max: 1919px; // lg max-width
$sm-min: 600px; // sm min-width
$md-min: 960px; // md min-width
$lg-min: 1280px; // lg min-width
$xl-min: 1920px; // xs min-width
$xs: 'only screen and (max-width: 599px)'; // mobiles
$sm: 'only screen and (min-width: 600px) and (max-width: 959px)'; // tablets
$md: 'only screen and (min-width: 960px) and (max-width: 1279px)'; // laptops
$lg: 'only screen and (min-width: 1280px) and (max-width: 1919px)'; //desktop
$xl: 'only screen and (min-width: 1920px) and (max-width: 5000px)'; // widescreen
$lt-sm: 'only screen and (max-width: 599px)';
$lt-md: 'only screen and (max-width: 959px)';
$lt-lg: 'only screen and (max-width: 1279px)';
$lt-xl: 'only screen and (max-width: 1919px)';
$gt-xs: 'only screen and (min-width: 600px)';
$gt-sm: 'only screen and (min-width: 960px)';
$gt-md: 'only screen and (min-width: 1280px)';
$gt-lg: 'only screen and (min-width: 1920px)';
/* Media queries */

:export {
  primaryColor: $primaryColor;
  primaryShadow: $primaryShadow;
  secondaryColor: $secondaryColor;
  secondaryButton: $secondaryButton;
  secondaryShadow: $secondaryShadow;
  darkGray: $darkGray;
  backGray: $backGray;
  greenButton: $greenButton;
  lightGray: $lightGray;
  grayLine: $grayLine;
  white: $white;
  black: $black;
  redError: $redError;
  greenSuccess: $greenSuccess;
}