@import './_variables.scss';

// INPUTS

legend {
	display: none !important;
}

.cssLabel {
	color: $lightGrayText !important;
	padding-bottom: 10px !important;
	top: -10px !important;
	left: -12px !important;
	font-size: 15px;
}

.cssLabelError {
	padding-bottom: 10px !important;
	top: -10px !important;
	left: -12px !important;
	font-size: 15px;
	color: $redError !important;
}

.cssLabelSuccess {
	padding-bottom: 10px !important;
	top: -12px !important;
	left: -12px !important;
	font-size: 15px;
	color: $lightGrayText !important;
}

//NEWS STYLES INPUT

.cssInput {
	font-size: 16px !important;
	top: 0 !important;
	border-radius: 4px !important;	
	border-color: $lightGrayText !important;
	font-family: $fontFamily !important;
	div { font-size: 16px !important; }
}

.cssInputError {
	font-size: 16px !important;
	top: 0 !important;
	border-radius: 4px !important;	
	border-color: $redError !important;
	color: $grayFont !important;
	div { font-size: 16px !important; }

	&::placeholder{
		color: $redError;
	}

	&:focus, &:active {
		border-color: $redError !important;
	}
}

.cssHelperTextError{
	background-color: #ffffff !important;
	margin: 0 !important;
	padding: 8px 12px 0 !important;

	&.background-white{
		background-color: #ffffff !important;
	}
}

.cssInputSuccess {
	font-size: 16px !important;
	top: 0 !important;
	border-radius: 4px !important;	
	border-color: $primaryColor !important;
	font-family: $fontFamily !important;
	div { font-size: 16px !important; }
}

.cssInputSelect {
	font-size: 16px !important;
	top: 0 !important;
	border-radius: 4px !important;	
	border-color: $primaryColor !important;
	font-family: $fontFamily !important;
	div { font-size: 16px !important; }
}

// END NEWS STYLES INPUTS

// Buttons
.primary-button {
    width: 214px;
    height: 60px;
    color: $primaryColor !important;
    border: $primaryColor 1px solid !important;
	cursor: pointer;
    font-size: 16px;
	font-weight: bold;
	border-radius: 4px;
    background: $white !important;
	font-family: $fontFamily;
    position: relative;
	&:disabled{
		border: none !important;
		color: $white !important;
		background: $lightGray !important;
		box-shadow: none;
	}

	&:hover {
        background: $primaryColor !important;
        color: $white !important;
	}
}


// Checkbox
.checkBox {
	color: $primaryColor !important;
}

.checkBoxActive {
	color: $primaryColor !important;
	&:checked {
		color: $white !important;
		background: $primaryColor !important;
	}
}

//spinner
.spinner-container {
	display: flex;
	flex-direction: column;
	align-content: center;
	justify-content: center;
	align-items: center;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 2000;
	position: fixed;
	background-color: rgba(0,0,0,.7);

	.circle {
		color: $white;
		width: 100px !important;
		height: 100px !important;
	}

	.text-spinner {
		margin-top: 10px;
        color: $white;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal
	}
}
